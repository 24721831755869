<template>
  <div id="welcome-container">
    <div id="room-background">
      <div id="logo-pos">
        <h1 class="playspace-logo">{{$t('global.app_name')}}</h1>
      </div> 
      <div id="welcome-content">
        <div id="support-fix">
          <Supportlink/>          
        </div>
        <b-row>
          <b-col cols="12" md="6">
            <slot />
          </b-col>
          <b-col cols="12" md="6">
            <div id="demo-container">
            <img class="demo-img" src="/img/elements/welcome/welcome_demo.png"  alt="Alien holding Playspaces App" title="Alien holding Playspaces App"/>
            <h2>{{$t('welcome.adheading')}}</h2>
            </div>
          </b-col>
        </b-row>
      </div>
      <Footer/>
    </div>
    
  </div>
</template>

<script>
// this is used for logged out pages (login, register, welcome, etc...)
import Supportlink from '@/components/supportlink.vue'
import Footer from '../components/footer.vue';
export default {
  name: "welcomelayout",
  components: {
    Footer,
    Supportlink
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#welcome-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1024px;
  max-height: 768px;
  z-index: 150;
  margin: 0 auto;
}
#welcome-content {
  position: relative;
  z-index: 100;  
  margin: 100px 0 0 0;
  padding: 0 70px;
  #support-fix {
    position: relative;
    top: 10px;
    right: 50px;
  }
  h2 {
    color: $ps-white;
  }
  .demo-img {
    border-radius: 30px;
    margin: 0 0 30px 0;
  }
  #demo-container {
    margin: 0 auto;
    max-width: 400px;
    min-width: 320px;
  }
}
</style>