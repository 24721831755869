<template>
  <div id="footer">
    <div class="copyright d-inline-block align-top">
        &copy; {{ new Date().getFullYear() }} {{$t('global.copyright')}}
    </div>
    <div class="links d-inline-block align-top">
      <a href="https://www.playspaces.co/privacy-policy" @click="trackLink('privacy-policy')" target="_blank">{{$t('global.privacypolicy')}}</a>
      <a href="https://www.playspaces.co/terms-of-use" @click="trackLink('terms')" target="_blank">{{$t('global.termsofservice')}}</a>
      <a href="https://www.playspaces.co/faq" @click="trackLink('faq')" target="_blank">{{$t('settings.faq')}}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "psfooter",
  methods: {
    trackLink(name){
      this.tracking({
          type: 'event',
          category: 'footer',
          action: 'external_link',
          label: name
      }); 
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#footer {
  position: absolute;
  bottom: 50px;
  right: 120px;
  .copyright {
    font-size: 10px;
    color:#D8D4FC;
    text-align: right;
    margin: 0 20px 0 0;
  }
  .links {
    a {
      color: $ps-white;
      font-weight: normal;
      text-decoration: none;
      margin: 0 20px 0 0;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
</style>
